import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const globalContext = createContext()

const Provider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState({
    filteredPosts: [],
    query: '',
  })
  const [navOpen, setNavOpen] = useState(false)
  const [pgRep, setPgRep] = useState()

  return (
    <globalContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        navOpen,
        setNavOpen,
        pgRep,
        setPgRep,
      }}
    >
      {children}
    </globalContext.Provider>
  )
}

export { Provider }

Provider.propTypes = {
  children: PropTypes.node,
}

export default ({ element }) => <Provider>{element}</Provider>
